/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Table} = Object.assign({}, _provideComponents(), props.components);
  if (!Table) _missingMdxReference("Table", true);
  return React.createElement(Table, null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Faroese"), "\n", React.createElement("th", null, "Transliteration"), "\n", React.createElement("th", null, "English"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "summar"), "\n", React.createElement("td", null, "sum-marr"), "\n", React.createElement("td", null, "Summer")), React.createElement("tr", null, React.createElement("td", null, "vetur"), "\n", React.createElement("td", null, "veh-tur"), "\n", React.createElement("td", null, "Winter")), React.createElement("tr", null, React.createElement("td", null, "vár"), "\n", React.createElement("td", null, "vahr"), "\n", React.createElement("td", null, "Spring")), React.createElement("tr", null, React.createElement("td", null, "heyst"), "\n", React.createElement("td", null, "hest"), "\n", React.createElement("td", null, "Autumn / Fall"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
